import { useState } from 'react'
import SectionForm from './SectionForm'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Spinner,
  UncontrolledDropdown
} from 'reactstrap'
import { useThemeBuilder } from 'ThemeManager/ThemeBuilder'
import { t } from 'i18next'
import FetchHock from 'helpers/FetchHock'
import { useDefaultLanguage } from 'Components/DefaultLanguage'
import clsx from 'clsx'

const SectionBuilder = ({ config, value, index }: any) => {
  const { updateSection, deleteSection } = useThemeBuilder()

  const {
    items: languages,
    loading,
    error
  } = FetchHock<{
    default: 0 | 1
    code: string
    title: string
  }>({ path: '/languages' })

  const [showForm, setShowForm] = useState<boolean>(false)
  const [modalData, setModalData] = useState()

  const defaultLang: string = useDefaultLanguage()

  const openForm = (i) => {
    setModalData(i)
    setShowForm(true)
  }
  const closeForm = () => {
    setShowForm(false)
    setModalData(undefined)
  }
  const hasNoForm = config?.form?.length === 0
  return (
    <div className="section-builder" >

      <div className="section-controlers">
      <Label className='fs-12 text-secondary-emphasis' >{config?.title} &nbsp;</Label>

        {!hasNoForm && (
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="btn btn-secondary btn-sm rounded-0 frist-button"
              tag="button">
              <i className="ri-settings-3-line"></i>
            </DropdownToggle>
            <DropdownMenu container={'body'} className="dropdown-menu-end">
              <DropdownItem onClick={() => openForm({ type: `edit`, lang: defaultLang })}>
                <span className="">
                  <i className="ri-edit-2-line me-2"></i>
                  {t('Edit')}
                </span>
              </DropdownItem>
              {loading ? (
                <DropdownItem>
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner size="sm" />
                  </div>
                </DropdownItem>
              ) : (
                languages &&
                languages.map((l, idx) => {
                  if (l?.default === 1) return null
                  return (
                    <DropdownItem
                      key={idx}
                      onClick={() => openForm({ type: `translate`, lang: l?.code })}>
                      <span className="">
                        <i className="ri-translate-2 me-2"></i>
                        {`${t('Translate')} ${t('to')} ${t(l?.code)}`}
                      </span>
                    </DropdownItem>
                  )
                })
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        {/* <button className="btn btn-secondary btn-sm rounded-0 frist-button" onClick={openForm}>
          <i className="ri-settings-3-line"></i>
        </button> */}
        <button
          className={clsx(
            'btn btn-secondary btn-sm ',
            !hasNoForm ? 'last-button rounded-0' : 'rounded-10 rounded-bottom-0'
          )}
          onClick={() => deleteSection(index)}>
          <i className="ri-delete-bin-line"></i>
        </button>
      </div>
      <div className="section-content">
        <img src={config.placeholder} />
      </div>
      {showForm && (
        <SectionForm
          data={modalData}
          close={closeForm}
          show={showForm}
          value={value}
          onSuccess={(value: any) => updateSection(index, value)}
          config={config}
        />
      )}
    </div>
  )
}

export default SectionBuilder
