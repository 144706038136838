import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
 
  {
    title: 'Section Heading',
    label: 'sectionHeading',
    type: 'translateString'
  },
  {
    title: 'Apply Filter',
    label: 'filter',
    type: 'select',
    options: [
      {
        label: 'most selling',
        value: 'most_selling'
      },
      {
        label: 'new arrivals',
        value: 'new_arrivals'
      },
      {
        label: 'week trades',
        value: 'week_trades'
      },
      {
        label: 'most discount',
        value: 'most_discount'
      }
    ]
  }
]
export default formula
