export const defaultImage = {
  url:  '/assets/placeholder/placeholder-item.svg',
  mobile: null,
  width: 450,
  height: 140
}

export const defaultValues = {
  image: '/assets/placeholder/placeholder-item.svg'
}
