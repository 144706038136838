import Formula from 'ThemeManager/types/Formula/index'

const formula: Formula = [
    {
        
            title: 'Show Category',
            label: 'show_category',
            type: 'checkBoox'
          
    }
  ]
  export default formula
