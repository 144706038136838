import Pagination from 'Components/IndexTable/partials/Pagination'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import fetch from 'helpers/server'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

const BestSellingProducts = ({ day = 0}) => {
  const { t } = useTranslation()
  const fetchHook = FetchHock<any, any>({
     path: '/dashboard/top-sell-products'
  })
  const [selected_day, setSelectedDay] = useState(day||-1);

  const handleSelect = (val) => {
    setSelectedDay(val); 

  };
  useEffect(() => {
    if (selected_day) {
      fetchHook.filter('day', selected_day)
    }
  }, [selected_day])
  const dateRanges = [
    { key: -1, label: t('Any') },
    { key: 1, label: t('Yesterday') },
    { key: 7, label: t('Last 7 days') },
    { key: 30, label: t('Last 30 days') },
    { key: 60, label: t('2 Months Ago') }
  ];
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Top Sold Products')}</h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown" direction="start">
              <DropdownToggle tag="a" className="text-reset" role="button">
                <span className="fw-semibold text-uppercase fs-12">Sort by: </span>
                <span className="text-muted">
                  {dateRanges.find((item) => item.key === selected_day)?.label}<i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu container={'body'} className="dropdown-menu-end">
              {dateRanges.map(({ key, label }) => (
        <DropdownItem key={key} onClick={() => handleSelect(key)}>
          {label}
        </DropdownItem>
      ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <ViewDataWithLoading
              style={{ height: '300px' }}
              items={fetchHook.items}
              loading={fetchHook.loading}
              error={fetchHook.error}>
              <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                <tbody>
                  {(fetchHook.items || []).map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm bg-light rounded p-1 me-2">
                            <img src={item.web_main_image} alt="" className="img-fluid d-block" />
                          </div>
                          <div>
                            <h5 className="fs-14 my-1">
                              <Link to="/apps-ecommerce-product-details" className="text-reset">
                                {item?.title || '...'}
                              </Link>
                            </h5>
                            <span className="text-muted">
                              {item.final_price} {item.currency}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.total}</h5>
                        <span className="text-muted">{t('Total')}</span>
                      </td>
                      {/* <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.stock}</h5>
                        <span className="text-muted">{t('Stock')}</span>
                      </td> */}
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">{item?.count}</h5>
                        <span className="text-muted">{t('Count')}</span>
                      </td>
                      <td>
                        <h5 className="fs-14 my-1 fw-normal">
                          {item.stock ? (
                            item.stock
                          ) : (
                            <span className="badge badge-soft-danger">{t('Out of stock')}</span>
                          )}{' '}
                        </h5>
                        <span className="text-muted">{t('Stock')}</span>
                      </td>
                      {/* <td>
                          <h5 className="fs-14 my-1 fw-normal">${item.amount}</h5>
                          <span className="text-muted">Amount</span>
                        </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ViewDataWithLoading>
          </div>

          {fetchHook.totalPages > 1 && (
            <Pagination
              options={fetchHook.options}
              totalPages={fetchHook.totalPages}
              totalItems={fetchHook.totalItems}
              showedItems={fetchHook.showedItems}
              pagenate={fetchHook.pagenate}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default BestSellingProducts
