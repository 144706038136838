import { defaultImage, defaultValues } from 'ThemeManager/Templates/DefaultValues'

export default {
  content: {
    title: {
      ar: 'تحدث إلى شخص حقيقي',
      en: 'Talk To A Real Person'
    },
    description: {
      ar: 'هل أنت على الحياد؟ لدي سؤال؟ بحاجة الى توصية؟ خدمات الأعضاء موجودة دائمًا للمساعدة. أرسل لنا رسالة.',
      en: 'Are you on the fence? Have a question? Need a recommendation? Member Services is always here to help. Send us a message.'
    },
    buttonText: {
      ar: 'الدردشة مع خدمات الأعضاء',
      en: 'Chat With Member Services'
    },
    supportImage: defaultImage
  }
}
