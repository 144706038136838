import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'

import clsx from 'clsx'
import BreadCrumb from 'Components/Common/BreadCrumb'
import IndexTable, { FilterItem, HeaderColumn } from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import ImageWithFallback from 'helpers/ImageComponent'
import { useEffect, useState } from 'react'
import OrdersCardsReport from './OrdersCardsReport'

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const OrdersList = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  document.title = 'Orders | Sallatk'

  const id = searchParams.get('id') || ''
  const fetchHook = FetchHock<Orders, any>({
    path: '/orders'
  })
  const { search } = fetchHook

  useEffect(() => {
    if (id) {
      fetchHook.filter('id', id)
    }
  }, [id])

  const headerColumns: HeaderColumn[] = [
    {
      title: 'No.'
    },
    {
      title: 'Total',
      accessor: 'total'
    },
    {
      title: 'User',
      accessor: 'user_id'
    },
    {
      title: 'Shipping company',
      accessor: 'shipping_company_id'
    },
    {
      title: 'Order status',
      accessor: 'order_status_id'
    },
    {
      title: 'Branch Name',
      accessor: 'branch_id'
    },
    {
      title: 'Created at',
      accessor: 'created_at'
    },
    {
      title: 'View'
    }
  ]
  const orderStatus: string[] = [
    'wait',
    'refused',
    'canceled',
    'review',
    'processing',
    'shipped',
    'return',
    'completed',
    'shipping'
  ]

  const [selectedCompany, setSelectedCompany] = useState(null)

  const handleCompanyChange = (value) => {
    setSelectedCompany(value)
    fetchHook.filter('payment_company_id', value)
  }

  const filterItems: FilterItem[] = [
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Customer'),
      path: 'customers',
      action: (value) => fetchHook.filter('user_id', value)
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Status'),
      path: 'order-status',
      action: (value) => fetchHook.filter('order_status', value)
    },

    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Shipping company'),
      path: 'shippingCompanies/offline',
      action: (value) => fetchHook.filter('shipping_company_id', value)
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Payment company'),
      path: 'company',
      action: handleCompanyChange
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Payment Method'),
      path: `payments?filter[company_id]=${selectedCompany || ''}`,
      action: (value) => fetchHook.filter('payment_method_id', value),
      isDisabled: !selectedCompany,
      id: 'paymentMethodFilter',
      renderItem: (item) => (
        <>
          {item}
          {!selectedCompany && (
            <UncontrolledTooltip placement="top" target="paymentMethodFilter">
              {t('Please select a payment company first')}
            </UncontrolledTooltip>
          )}
        </>
      )
    },
    {
      type: 'select',
      col: 2,
      placeholder: t('Order status'),
      options: [
        {
          label: 'All',
          value: ''
        },
        ...orderStatus.map((item) => ({
          label: capitalize(item),
          value: item
        }))
      ],
      action: (value) => fetchHook.filter('status_code', value)
    },
    {
      type: 'date',
      col: 2,
      placeholder: t('Start date'),
      action: (value) => {
        fetchHook.filter('created_at', value[0], 'from')
      }
    },
    {
      type: 'date',
      col: 2,
      placeholder: t('End date'),
      action: (value) => {
        fetchHook.filter('created_at', value[0], 'to')
      }
    },
    {
      type: 'asyncSelect',
      col: 2,
      placeholder: t('Branch Name'),
      path: 'branches',
      action: (value) => fetchHook.filter('branch_id', value)
    },
    {
      type: 'search',
      col: 6,
      placeholder: t('Search ...'),
      action: (value) => search(value)
    }
  ]

  const tabs = [
    {
      title: t('All'),
      icon: 'ri-store-fill',
      action: () => fetchHook.filter('status', '')
    },
    {
      title: t('Completed'),
      icon: 'ri-checkbox-circle-line',
      action: () => fetchHook.filter('status', 'completed')
    },
    {
      title: t('Returned'),
      icon: 'ri-arrow-left-right-line',
      action: () => fetchHook.filter('status', 'return')
    },
    {
      title: t('Wait'),
      icon: 'ri-time-line',
      action: () => fetchHook.filter('status', 'wait')
    }
  ]
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <OrdersCardsReport />
        <Row>
          <Col lg={12}>
            <IndexTable
              addString={`${t('Add')} ${t('Order')}`}
              addAction={() => { }}
              addRoute="/orders/create"
              tabs={tabs}
              title={t('Orders')}
              headerColumns={headerColumns}
              {...fetchHook}
              filters={filterItems}>
              {fetchHook?.items?.map((item, index: number) => (
                <tr key={index} style={item.is_read == 0 ? { fontWeight: 'bold' } : {}}>
                  <td>
                    <h5 className="fs-14 mb-1">
                      <Link to={`/orders/${item.id}`} className="text-body text-uppercase">
                        #{item.order_number}{' '}
                      </Link>
                    </h5>
                  </td>
                  <td>{item?.total}</td>
                  <td>
                    <div className="d-flex ">
                      <div
                        className={clsx(
                          item.user.is_deleted ? 'text-decoration-line-through' : ''
                        )}>
                        {item.user?.name || null + item.user?.lastname || null}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex ">
                      <div className="avatar-sm bg-light rounded">
                        <ImageWithFallback
                          src={item?.shipping_company?.web_logo}
                          alt={item?.shipping_company?.title}
                          className="img-fluid d-block"
                        />
                      </div>
                      <div>{item?.shipping_company?.title}</div>
                    </div>
                  </td>

                  <td>
                    <div>{item?.order_status?.title}</div>
                  </td>
                  {/* <td>
                    <div className="form-check form-switch-md form-switch form-switch-secondary">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        onChange={() =>
                          update(item?.id, {
                            published: item?.published === 1 ? 0 : 1
                          })
                        }
                        checked={getCheckboxValue(item?.published === 1, item?.id)}
                      />
                    </div>
                  </td> */}
                  <td>
                    <div>{item?.branch?.name}</div>
                    <div>{item?.branch?.address}</div>
                  </td>

                  <td>{item.created_at}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <Link className='btn btn-outline-primary btn-sm' color="link" to={`/orders/${item?.id}`}>
                        <i className="ri-eye-fill fs-16"></i>
                      </Link>
                      <Button color="danger" outline size='sm' onClick={() => fetchHook.openDelete(item.id)}>  
                        <i className=" ri-delete-bin-line fs-16"></i> 
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </IndexTable>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OrdersList

const Custom = (prop) => {
  const { t } = useTranslation()

  return (
    <div className="form-group">
      <label className="form-control-label">{t('Search by product name')}</label>
      <input
        type="text"
        className="form-control"
        placeholder={t('Search by product name')}
        value={prop.fetchHook.options.filter.order_number}
        onChange={(e) => {
          prop.fetchHook.filter('order_number', e.target.value)
        }}
      />
    </div>
  )
}
