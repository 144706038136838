import UpdateHock from 'helpers/UpdateHock'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionItem, Col, Row } from 'reactstrap'

import Assets from '../Components/Assets'
import ProductSidebar from '../Components/ProductSidebar'
import { DigitalProduct } from '../types'
import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import OrderForm from './components/OrderForm'
import ProductsLayout from './components/ProductsLayout'

const Digital = () => {
  document.title = 'Update product | Sallatk'

  const { id } = useParams()

  const numericId: number = id as unknown as number

  const uploadMethods = UpdateHock<DigitalProduct>({
    path: '/digital_product',
    editId: numericId,
    editResponseBeforeRegister(data) {
      if (data?.assets) {
        data.assets = data.assets.map((asset) => ({ ...asset, status: 'uploaded' }))
      }
      return data
    }
  })

  const [opened, setOpen] = useState<string>('')

  const selectLable = (label: string) => {
    setOpen(opened === label ? '' : label)
  }

  const { closeUploading, openUploading } = uploadMethods
  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <Assets openUploading={openUploading} closeUploading={closeUploading} />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <OrderForm
                isOpen={opened == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar isEdit components={{ Shipping: false, Stock: false }} />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Digital
