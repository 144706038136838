import { useEffect, useState } from 'react';
import FetchHook from '../helpers/FetchHock'
import { useTranslation } from 'react-i18next';
import Loader from 'Components/Common/Loader';
import ImageWithFallback from 'helpers/ImageComponent';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const StoreInfo = ({ setStore }: any) => {
  const { result:profile, loading, error } = FetchHook<{ store: { url: string; name: string; logo: string } }>({
    path: '/profile',
  })

  useEffect(() => {
    if (profile && profile.store) {
      setStore(profile.store)  // Pass the store to the parent component via setStore
    }
  }, [profile, setStore])



  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  // const { userProfile } = UseProfile()


  if (loading) {
    return <Loader />
  }

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const url = profile?.store?.url
  const logo = profile?.store?.logo
  const name = profile?.store?.name

  return (
    <li className="menu-title">
      <span data-key="t-menu" className="w-100">
        <div className="d-flex gap-2 align-items-center justify-content-between w-100">
          <div className="d-flex gap-2 align-items-center">
            <ImageWithFallback src={logo} alt="" height={30} width={30} className="" />
            <p className="mb-0">{name}</p>
          </div>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="p-1" tag={'span'}>
              <i
                className="ri ri-more-2-fill cursor-pointer fs-14 d-block"
                style={{
                  color: '#6d7080'
                }}></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => navigator.clipboard.writeText(url)}>
                {t('Copy URL')}
              </DropdownItem>
              <DropdownItem onClick={() => window.open('https://' + url, '_blank')}>
                {t('Go to Store')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </span>
    </li>
  )
}

export default StoreInfo
