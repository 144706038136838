import React from 'react'
import { Col, Row } from 'reactstrap'
import { UseProfile } from 'helpers/UserHooks'
import { useTranslation } from 'react-i18next'
import AddProductsDropdownButton from 'pages/Products/Products/list/components/AddProductsDropdownButton'

const Section = (props: any) => {
  const { t } = useTranslation()
  const { userProfile } = UseProfile()
  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">
                {t('Hello')}, {userProfile?.name}
              </h4>
              <p className="text-muted mb-0">Here's what's happening with your store today.</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <Row className="g-3 mb-0 align-items-center">
                <div className="col-auto">
                  <AddProductsDropdownButton btnClassName="btn btn-soft-primary" />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-icon waves-effect waves-light layout-rightside-btn"
                    onClick={props.rightClickBtn}>
                    <i className="ri-pulse-line"></i>
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Section
