import CreateHook from 'helpers/CreateHook'
import { Accordion, AccordionItem, Col, Row } from 'reactstrap'

import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'

import ProductSidebar from '../Components/ProductSidebar'
import CourseDetails from './components/CourseDetails'
import ProductsLayout from './components/ProductsLayout'
import Specification from './components/Specification'
import { useState } from 'react';

const Course = () => {
  document.title = 'Create product | Sallatk'

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string

    brand_id: number | null
    published: number
    cost: number | null
    price: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
  }

  // prepare hock
  const createMethods = CreateHook<readyProduct>('/course_product', {
    title: '',
    slug: '',
    short_description: '',
    description: '',
    brand_id: null,
    published: 1,
    cost: null,
    price: null,
    badge_id: null,
    categories: [],
    fees: [],
    main_image: '',
    images: [],
    labels: [],
    form_id: null,
    discount: null,
    discount_type: '',
    similar_products: [],
    meta: {
      title: '',
      keywords: '',
      description: ''
    }
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opend === label ? '' : label)
  }

  const { openUploading, closeUploading } = createMethods
  return (
    <ProductsLayout createMethods={createMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <CourseDetails openUploading={openUploading} closeUploading={closeUploading} />
          <MainPrice />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          {/* <Details register={register} errors={errors} />
              <Similar register={register} errors={errors} />
              <Seo register={register} errors={errors} /> */}
          <ProductSidebar
            components={{
              Shipping: false,
              Stock: false
            }}
          />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Course
