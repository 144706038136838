import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row, Card, CardBody, CardHeader } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { Search, Submit, Switch, Input } from 'Components/Form'
import UpdateHock from 'helpers/UpdateHock'
import Loader from 'Components/IndexTable/partials/Loader'
import GoogleMapComponent from '../create/components/GoogleMapComponent'
import { Controller, FormProvider } from 'react-hook-form'
import { BranchType } from '../types'
import { ErrorBoundary } from 'react-error-boundary'

const UpdateBranch = () => {
  document.title = 'Update branch | Sallatk'

  const { t } = useTranslation()
  const params = useParams()
  const history = useNavigate()

  const {
    register,
    handleSubmit,
    errors,
    loading,
    getLoading,
    update,
    getValues,
    control,
    formMethods
  } = UpdateHock<BranchType>({
    path: '/branches',
    initValue: {
      latLng: {
        lat: '',
        lng: ''
      }
    },
    editId: parseInt(params?.id as string),
    editResponseBeforeRegister: (data) => {
      return {
        ...data,
        latLng: {
          lat: data.lat,
          lng: data.lng
        }
      }
    }
  })

  const submit = async (data: BranchType) => {
    const latLng = data.latLng
    const payload = latLng
      ? {
          ...data,
          lat: latLng?.lat,
          lng: latLng?.lng
        }
      : data
    const response = await update(payload)
    if (response.status) history(-1)
  }
  const isMain = Number(getValues('main')) === 1

  return (
    <div className="page-content">
      {getLoading && <Loader />}
      {!getLoading && (
        <Container fluid>
          <BreadCrumb />
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(submit)}>
              <Card>
                <CardHeader>
                  <h5 className="mb-0">{t('Branch details')}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Input
                        label={t('Branch Name')}
                        placeholder={t('Enter branch name')}
                        model={register('name', {
                          required: t('Branch name is required'),
                          minLength: {
                            value: 3,
                            message: t('Branch name must be bigger than or equal 3')
                          }
                        })}
                        error={errors.name}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label={t('Address')}
                        placeholder={t('Enter address')}
                        model={register('address', {
                          required: t('Address is required'),
                          minLength: {
                            value: 3,
                            message: t('Address must be bigger than or equal 3')
                          }
                        })}
                        error={errors.address}
                        // disabled={isMain}
                      />
                    </Col>
                    <Col md={6}>
                      <Search
                        label={t('City')}
                        placeholder={t('Enter city')}
                        model={register('city_id', {
                          required: t('City is required')
                        })}
                        value={getValues('city')}
                        propTitle="title"
                        error={errors.city_id}
                        path="cities"
                        disabled={isMain}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        label={t('Max order')}
                        type="number"
                        placeholder={t('Enter max order')}
                        model={register('max_order_day')}
                        error={errors.max_order_day}
                        atEnd={t('Per day')}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        label={t('Minimum cart')}
                        type="number"
                        placeholder={t('Enter minimum cart cost')}
                        model={register('minimum_cart')}
                        error={errors.minimum_cart}
                      />
                    </Col>
                    <Col md={6} />
                    <Col md={4}>
                      <Switch
                        label={t('Allow pickup')}
                        model={register('allow_pickup')}
                        error={errors.allow_pickup}
                        defaultChecked={getValues('allow_pickup') === 1}
                      />
                    </Col>
                    {/* <Col md={4}>
                      <Switch
                        label={t('Active')}
                        model={register('available')}
                        error={errors.available}
                        defaultChecked={getValues('available') === 1}
                      />
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="mb-0">{t('Branch location')}</h5>
                </CardHeader>
                <CardBody>
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    <Controller
                      control={control}
                      rules={{
                        required: t('Location is required')
                      }}
                      name="latLng"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <GoogleMapComponent onChange={onChange} value={value} error={error} />
                      )}
                    />
                  </ErrorBoundary>
                </CardBody>
              </Card>
              <div className="hstack gap-2 justify-content-start mb-2">
                <Submit loading={loading} label={t('Update')} />
              </div>
            </form>
          </FormProvider>
        </Container>
      )}
    </div>
  )
}

export default UpdateBranch
