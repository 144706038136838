import ImageWithFallback from 'helpers/ImageComponent'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge, Card, CardBody, CardHeader } from 'reactstrap'
//Logistics Details

// shipping_company
function ShippingDetails({ data }) {
const shipping =data.shipping_company
const address = data.address
  const { t } = useTranslation()
  if (!shipping) return null
  return (
    <Card>
      <CardHeader>
        <div className="d-flex">
          <h5 className="card-title flex-grow-1 mb-0">
            <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
            {t('Shipping Details')}
          </h5>
          <div className="flex-shrink-0">
            <Link to="#" className="badge bg-primary-subtle text-primary fs-11">
              Track Order
            </Link>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="text-center">
          {/* <i className="ri-truck-line display-5 text-danger"></i> */}
          <ImageWithFallback src={shipping?.web_logo} alt={shipping?.title} className="avatar-lg rounded" />
          <h5 className="fs-16 mt-2">{shipping?.title}</h5>
          <p className="text-muted mb-0">
            {shipping?.is_active === 1 ? (
              <Badge color="success">{t('Active')}</Badge>
            ) : (
              <Badge color="danger">{t('Not active')}</Badge>
            )}
          </p>
        </div>

        <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
          <li className="fw-medium fs-14">{data?.name}</li>
          <li>
            <span className="fw-medium">{t('Deliver To')}:</span> &nbsp;
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${address?.region?.title || ''}+${address?.city?.title || ''}+${address?.country?.title || ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >&nbsp;
            <i className="ri-map-pin-line fs-16"> </i> &nbsp; {address?.title} </a>
          </li>
         
          <li>
            <span className="fw-medium">{t('Address')}:</span> {address?.address}
          </li>
          <li>
            <span className="fw-medium">{t('Street')}:</span> {address?.street}
          </li>
          <li>
            <span className="fw-medium">{t('Neighborhood')}:</span> {address?.neighborhood}
          </li>
          <li>
            <span className="fw-medium">{t('City')}:</span> {address?.country.title} , {address?.city.title} ,{address?.region.title}
          </li>
          <li>
            <span className="fw-medium">{t('Note')}:</span> {address?.note}
          </li>
        </ul>
      </CardBody>
    </Card>
  )
}

export default ShippingDetails
