import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode-generator';
import { Button } from 'reactstrap';
import PopUp from 'Components/PopUp';

const QRCodeShare = ({ text }: { text: string }) => {
  const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false); // State to control popup visibility
  const [qrCodeImage, setQrCodeImage] = useState<string>('');

  // Generate the QR Code and store it in state
  useEffect(() => {
    const generateQRCode = () => {
      const qr = QRCode(0, 'L'); // Create a QR code instance
      qr.addData(text); // Add the text to the QR code
      qr.make(); // Generate the QR code
      return qr.createDataURL(); // Get the QR code as a data URL
    };

    // Only update the state if the `text` is valid
    if (text) {
      const qrDataURL = generateQRCode();
      setQrCodeDataURL(qrDataURL);
    }
  }, [text]); // Depend on `text`

  // Download function
  const downloadQRCode = () => {
    if (!qrCodeDataURL) return;

    const a = document.createElement('a');
    a.href = qrCodeDataURL;
    a.download = 'qrcode.png';
    a.click();
  };
  // Share function using the Web Share API
  const shareQRCode = () => {
    if (navigator.share) {
      navigator.share({
        title: 'QR Code',
        text: 'Scan this QR code!',
        url: qrCodeDataURL, // Share the QR code as the image URL
      })
        .then(() => console.log('QR Code shared successfully'))
        .catch((error) => console.log('Error sharing QR Code:', error));
    } else {
      alert('Web Share API is not supported on this device');
    }
  };

  // Handle showing image in the modal
  const showImage = () => {
    setQrCodeImage(qrCodeDataURL); // Set the image URL for the modal
    setShowModal(true); // Show the modal
  };

  // Handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Render the component
  return (
    <div className="d-grid">
      {/* QR Code Image */}
      {qrCodeDataURL && (
        <div className="">
          <img
            src={qrCodeDataURL}
            alt="QR Code"
            style={{ width: 100, height: 100 ,cursor:'pointer'}}

            onClick={showImage}
          />
        </div>
      )}
      <div>
        {/* Share Button */}
        <Button onClick={shareQRCode} className="btn btn-secondary" size="sm">
          <i className="ri-share-forward-line"></i>
        </Button> &nbsp;
        {/* Download Button */}
        <Button onClick={downloadQRCode} className="btn btn-primary" size="sm">
          <i className="ri-download-line"></i>
        </Button>
      </div>
      {/* Modal to show QR code in larger view */}
      {showModal && (
        <PopUp title="QR Code" show={showModal} close={closeModal}>
          <img src={qrCodeImage} alt="QR Code" style={{ width: '100%' }} />
        </PopUp>
      )}
    </div>
  );
};

export default QRCodeShare;
