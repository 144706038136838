// react elements
import { Link, useNavigate } from 'react-router-dom'
// botstrab elements
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from 'reactstrap'
// page BreadCrumb
import BreadCrumb from 'Components/Common/BreadCrumb'
// translation
import { useTranslation } from 'react-i18next'
// themes imaages
import minimal from 'assets/images/themes/minimal.png'
import ThemeOptions from './component/options'

const FileManager = () => {
  document.title = 'Themes | Sallatk'
  const { t } = useTranslation()
  const navigate = useNavigate()

  const Themes = [
    // { title: t('Modern'), image: modern },
    { title: t('Minimal'), image: minimal }
    // { title: t('Classic'), image: classic },
    // { title: t('Standerd'), image: standerd },
    // { title: t('Vintage'), image: vintage },
    // { title: t('Trendy'), image: trendy },
    // { title: t('Elegant'), image: elegant },
    // { title: t('Refined'), image: refined },
    // { title: t('Ancient'), image: ancient }
  ]
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb />

          <ThemeOptions ></ThemeOptions>
          <div className="d-flex justify-content-between g-2 mb-4">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2 d-block d-lg-none">
                <button
                  type="button"
                  className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                  <i className="ri-menu-2-fill align-bottom"></i>
                </button>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-16 mb-0">{t('Themes')}</h5>
              </div>
            </div>


          </div>
          <Row >
            {Themes.map((item, index) => (
              <Col xl={3}>
                <Card className="overflow-hidden">
                  <CardBody className="d-flex">

                    <img src={item.image} alt="" className="img-fluid" />
                  </CardBody>
                  <CardFooter>

                    <div className="float-end">
                      <UncontrolledDropdown className="card-header-dropdown">
                        <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                          <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" end>
                          <DropdownItem
                            className="view-item-btn"
                            tag={Link}
                            to={`/themes/customize/${index + 1}`}>
                            {t('Settings')}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 ps-3">
                        <h5 className="text-muted text-uppercase fs-13 mb-0">{item.title}</h5>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </Col>

            ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default FileManager
