import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Input, Submit, ImageUpload, Switch, Textarea } from 'Components/Form'
import CreateHook from 'helpers/CreateHook'
import PopUp from 'Components/PopUp'
import { UseProfile } from 'helpers/UserHooks'
import { useParams } from 'react-router'

const CreateModal = ({ close, show, onSuccess }: any) => {
  const { t } = useTranslation()
  const { userProfile } = UseProfile()
  const store = userProfile?.store
  const url = store?.url
  const  locale  = "en";
  return (
    <PopUp title={t('Theme Preview')} show={show} close={close} size='xl'>
    <iframe
              src= {url + '/' +locale+'/draft'}
              style={{ width: '100%', height: '400px', border: 'none' }}
              title="Embedded Content"
            />
        <div className="hstack gap-2 justify-content-end">
          <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
            <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
          </Button>
          <Submit  label={t('Create')} />
        </div>
    
    </PopUp>
  )
}
export default CreateModal
