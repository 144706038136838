// required components
import { useState } from 'react'
import { useThemeBuilder } from '..'
import SectionBuilder from './SectionBuilder'
import config from 'ThemeManager/Templates/Config'
import CreateModal from './components/CreateModal'

const Previewer = () => {
  const { sections, handelDrop, showDragPositions, handelDragOver } = useThemeBuilder()
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const [dropIndex, setDropIndex] = useState<number | null>(null)

  // const openCreate = (dropIndex) => {changeShowCreate(true)}
  const openCreate = (dropIndex: number) => {
    setDropIndex(dropIndex) // Set the dropIndex
    changeShowCreate(true); // Show the create modal
  };
  const closeCreate = () => changeShowCreate(false)
  const updateIndex = (index: number) => {
    setDropIndex(index)
  }

  // Helper function to render drag position indicator
  const renderDragPosition = (dropIndex: number) => (
    <div
      className="border border-primary text-center text-primary w-100 rounded-0 py-2 mt-1 border-dashed my-2"
      onClick={(e) =>  openCreate(dropIndex)}
      // onDrop={(e) => handelDrop(e, dropIndex)}       onDragOver={(e) => handelDragOver(e)}
      >
      +
    </div>
  )

  return (
    <div className="theme-builder-container p-3"   style={{ width: '80%' }}>
      <div className="theme-builder-background ">
        {showDragPositions && renderDragPosition(0)}
        {sections?.map((section: any, index: number) => {
          const sectionConfig = findSectionConfig(config, section)

          if (!sectionConfig) return null
          return (
            <span key={index}>
              <SectionBuilder
                index={index}
                value={section.data.data}
                config={sectionConfig?.config}
              />
              {showDragPositions && renderDragPosition(index + 1)}
            </span>
          )
        })}
      </div>
      {showCreate && <CreateModal   dropIndex={dropIndex}  close={closeCreate} show={openCreate} onSuccess={updateIndex} />}
</div>
  )
}

export default Previewer

const findSectionConfig = (config, section) => {
  const matchedSection = config.find((el) => el.key === section.type)

  if (!matchedSection || !matchedSection.sections) return undefined

  return matchedSection.sections.find((el) => el.key === section.data.type)
}
