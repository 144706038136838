import { useTranslation } from 'react-i18next'

import { useNavigate, useParams } from 'react-router-dom'

import { Accordion, AccordionItem, Col, Container, Form, Row } from 'reactstrap'

import { Submit } from 'Components/Form'
import Loader from 'Components/IndexTable/partials/Loader'
import UpdateHock from 'helpers/UpdateHock'

import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'

import ProductSidebar from '../Components/ProductSidebar'
import Features from './components/Features'
import OrderForm from './components/OrderForm'
import Specification from './components/Specification'
import { useState } from 'react'

const ProductWithAddedFeatures = () => {
  document.title = 'Create Product | Salltak'
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    sku: string
    MPN: string
    GTIN: string
    points: number | null
    brand_id: number | null
    hidden: number | null
    cost: number | null
    price: number | null
    weight_unit: string
    weight: number | null
    quantity: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    labels: number[]
    form_id: number | null
    discount: number | null
    discount_type: string
    is_free_shipping: number | null
    cod_allowed: number | null
    stock_min_alert: number | null
    shipping_allowed: number | null
    ready_in: number | null
    ready_in_type: string
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOption: {
      discount: string
      discount_type: string
      cost: string
      price: string
      quantity: string
    }[]
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    update,
    loading,
    closeUploading,
    openUploading,
    setValue,
    getValues,
    getLoading,
    control
  } = UpdateHock<readyProduct>({
    path: '/ready_product_features',
    initValue: {
      title: '',
      slug: '',
      short_description: '',
      description: '',
      sku: '',
      MPN: '',
      GTIN: '',
      points: null,
      brand_id: null,
      hidden: null,
      cost: null,
      price: null,
      weight_unit: '',
      weight: null,
      quantity: null,
      badge_id: null,
      categories: [],
      fees: [],
      main_image: '',
      images: [],
      labels: [],
      form_id: null,
      discount: null,
      discount_type: '',
      is_free_shipping: null,
      cod_allowed: null,
      stock_min_alert: null,
      shipping_allowed: null,
      ready_in: null,
      ready_in_type: '',
      similar_products: [],
      meta: {
        title: '',
        keywords: '',
        description: ''
      },
      featureOption: []
    },
    editId: numericId
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opend === label ? '' : label)
  }

  const submit = async (data: readyProduct) => {
    const response = await update(data)
    if (response.status) navigate('/products/list')
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Form onSubmit={handleSubmit(submit)}>
          {getLoading && <Loader />}
          {!getLoading && (
            <Row>
              <Col lg={8}>
                <MainDetails
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />

                <Features
                  register={register}
                  errors={errors}
                  control={control}
                  getValues={getValues}
                />

                <Gallery
                  register={register}
                  errors={errors}
                  openUploading={openUploading}
                  closeUploading={closeUploading}
                  getValues={getValues}
                />

                <Accordion
                  toggle={() => {}}
                  className="lefticon-accordion custom-accordionwithicon accordion-border-box"
                  open="test">
                  <AccordionItem>
                    <Specification
                      isOpen={opend == 'spicifcation'}
                      toggleItem={() => selectLable('spicifcation')}
                      register={register}
                      getValues={getValues}
                    />
                  </AccordionItem>
                  <AccordionItem>
                    <OrderForm
                      isOpen={opend == 'orderForm'}
                      toggleItem={() => selectLable('orderForm')}
                      register={register}
                      errors={errors}
                      getValues={getValues}
                    />
                  </AccordionItem>
                </Accordion>
                <div className="text-end mb-3 m-2">
                  <Submit loading={loading} label={t('Save')} />
                </div>
              </Col>

              <Col lg={4}>
                <ProductSidebar />
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    </div>
  )
}

export default ProductWithAddedFeatures
