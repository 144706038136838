import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
// bootstrap elements
import { Accordion, AccordionItem, Col, Row } from 'reactstrap'
// reqired componnent
import UpdateHock from 'helpers/UpdateHock'

import IndexTable from 'Components/IndexTable'
import FetchHock from 'helpers/FetchHock'
import { useTranslation } from 'react-i18next'
import ProductSidebar from '../Components/ProductSidebar'
import { CardProduct } from '../types'
import CreateModal from './components/CardCreateModal'
import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import OrderForm from './components/OrderForm'
import ProductsLayout from './components/ProductsLayout'

const Card = () => {
  document.title = 'Update product | Sallatk'

  const { id } = useParams()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  // prepare hock
  const uploadMethods = UpdateHock<CardProduct>({
    path: '/card_product',
    editId: numericId
  })

  const [opened, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opened === label ? '' : label)
  }

  const { closeUploading, openUploading } = uploadMethods
  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <CardProductCards />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            {/* <AccordionItem>
              <Specification
                isOpen={opened == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
                register={register}
                errors={errors}
                control={control}
              />
            </AccordionItem> */}
            <AccordionItem>
              <OrderForm
                isOpen={opened == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar isEdit components={{ Shipping: false, Stock: false }} />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Card

type CardDetails = {
  id: number
  card: string
  sort: number
  product_id: number
  sold_at: string | null
  order_id: string | null
}

function CardProductCards() {
  const { id } = useParams()
  const { t } = useTranslation()

  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => changeShowCreate(true)
  const closeCreate = () => changeShowCreate(false)

  const fetchHock = FetchHock<CardDetails>({
    path: `/product/${id}/cards`
  })

  const { items, fetch, openDelete } = fetchHock

  return (
    <>
      <IndexTable
        {...fetchHock}
        addString={`${t('Add')} ${t('Card')}`}
        addAction={openCreate}
        headerColumns={[
          {
            title: 'Card'
          },
          {
            title: 'Sold at'
          },

          {
            title: 'Order id'
          },
          {
            title: 'Action'
          }
        ]}>
        {items?.map((item, index) => (
          <tr key={index}>
            <td>{item.card}</td>
            <td>{item.sold_at}</td>
            <td>{item.order_id}</td>
            <td>
              <button
                type="button"
                onClick={() => openDelete(item.id)}
                className="btn btn-sm btn-outline-danger">
                <i className="ri ri-delete-bin-5-line"></i>
              </button>
            </td>
          </tr>
        ))}
      </IndexTable>
      {showCreate && <CreateModal close={closeCreate} show={showCreate} onSuccess={fetch} />}
    </>
  )
}
