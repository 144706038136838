import { Button, Col, Collapse, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PopUp from 'Components/PopUp'
import { Card, CardBody, CardHeader } from 'reactstrap'
import config from 'ThemeManager/Templates/Config'
import { useState } from 'react'
import { useThemeBuilder } from 'ThemeManager/ThemeBuilder'

const CreateModal = ({ close, show, onSuccess, dropIndex }: any) => {
  const { t } = useTranslation()
  const [openSection, setOpenSection] = useState<string | null>(null)
  const [selectItem, setSelectedItem] = useState(null)
  const { addSection } = useThemeBuilder()

  const toggleSection = (sectionKey: string) => {
    setOpenSection(openSection === sectionKey ? null : sectionKey)
  }

  const handleSelect = (item: any) => {
    setSelectedItem(item)
  }

  const updateTemplate = () => {
    if (!selectItem) return
    const newItem = {
      type: selectItem.key,
      data: {
        type: selectItem.el.key,
        data: selectItem.el.config.default,
      },
    }
    addSection(newItem, dropIndex)
    dropIndex++
   onSuccess(dropIndex)
  }
  return (
    <PopUp show={show} close={close} size="lg">
      <Container fluid>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">{t('Homepage Sections')}</h5>
          </CardHeader>
          <CardBody>
            {config.map((section: any, index: number) => (
              <div key={index}>
                {/* Section Header */}
                <div
                  className="d-flex justify-content-between mb-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleSection(section.key)}
                >
                  <h6>{t(section.title)}</h6>
                  <i
                    className={`ri-arrow-${openSection === section.key ? 'up' : 'down'}-s-line fs-18`}
                  ></i>
                </div>

                {/* Collapsible Section */}
                <Collapse isOpen={openSection === section.key}>
                  <Row>
                    {section.sections.map((el: any, idx: number) => (
                      <Col
                        md={6}
                        className="position-relative"
                        style={{ cursor: 'pointer' }}
                        key={idx}
                        onMouseEnter={() =>
                          handleSelect({ el: el, key: section.key, index: idx })
                        }
                      >
                        <Card
                          className={`mb-2 ${
                            selectItem?.el === el ? 'border border-primary' : ''
                          }`}
                        >
                          <CardBody className="text-center p-2">
                            <img
                              src={el.config.placeholder}
                              style={{ width: '100%' }}
                              alt=""
                            />
                            <br />
                            {t(el.title)}
                          </CardBody>
                        </Card>
                        {/* Create button visible on hover */}
                        {selectItem?.el === el && (
                          <Button
                            className="position-absolute bottom-0 start-50 translate-middle-x"
                            color="primary"
                            onClick={updateTemplate}
                          >
                            {t('Create')}
                          </Button>
                        )}
                      </Col>
                    ))}
                  </Row>
                </Collapse>
                <hr />
              </div>
            ))}
          </CardBody>
        </Card>
      </Container>
      <div className="hstack gap-2 justify-content-end">
        <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
          <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
        </Button>
      </div>
    </PopUp>
  )
}

export default CreateModal
