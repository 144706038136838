import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import LogoStyleModal from './LogoStyleModal';
import FontsAndColors from './FontsAndColors';

const ThemeOptions = ({ close, show, onSuccess }: any) => {
const { t } = useTranslation();
const navigate = useNavigate();
const [showLogoStyleModal, setShowLogoStyleModal] = useState(false);
const [showFontsAndColor, setFontsAndColor] = useState(false);
return (

<div className="row">
    <div className="d-flex align-items-center mb-3">

        <div className="flex-grow-1">
            <h5 className="fs-16 mb-0">{t('Theme Options')}</h5>
        </div>
    </div>
    {/* Header Menu */}
    <a className="col-6 folder-card col-xxl-2 cursor-pointer" onClick={()=> navigate('/menus/header')}>
        <div id="folder-2" className="bg-light shadow-none card">
            <div className="card-body">
                <div className="text-center">
                    <h6 className="fs-15 FontsAndColors link-primary"> {t('Header Menu')}</h6>
                </div>
            </div>
        </div>
    </a>

    {/* Footer Menu */}
    <div className="col-6 folder-card col-xxl-2 cursor-pointer" onClick={()=> navigate('/footer')}>
        <div id="folder-2" className="bg-light shadow-none card">
            <div className="card-body">
                <div className="text-center">
                    <h6 className="fs-15 FontsAndColors link-primary"> {t('Footer Menu')}</h6>
                </div>
            </div>
        </div>
    </div>
    {/* Mobile Logo Style */}
    <div className="col-6 folder-card col-xxl-2 cursor-pointer" onClick={()=> setShowLogoStyleModal(true)}>
        <div id="folder-2" className="bg-light shadow-none card">
            <div className="card-body">
                <div className="text-center">
                    <h6 className="fs-15 FontsAndColors link-primary"> {t('Mobile Logo Style')}</h6>
                </div>
            </div>
        </div>
    </div>
    {/* Fonts & Colors */}
    <div className="col-6 folder-card col-xxl-2 cursor-pointer" onClick={()=> setFontsAndColor(true)}>
        <div id="folder-2" className="bg-light shadow-none card">
            <div className="card-body">
                <div className="text-center">
                    <h6 className="fs-15  link-primary"> {t('Fonts & Colors')}</h6>
                </div>
            </div>
        </div>
    </div>
    {showLogoStyleModal && ( <LogoStyleModal close={()=> setShowLogoStyleModal(false)} show={showLogoStyleModal} /> )}
    {showFontsAndColor && (<FontsAndColors close={()=> setFontsAndColor(false)} show={showFontsAndColor} />)}
</div>
);
}
export default ThemeOptions
