import UpdateHock from 'helpers/UpdateHock'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionItem, Col, Row } from 'reactstrap'

import ProductSidebar from '../Components/ProductSidebar'
import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import OrderForm from './components/OrderForm'
import ProductsLayout from './components/ProductsLayout'
import Specification from './components/Specification'

const Default = () => {
  document.title = 'Update product | Sallatk'
  const { id } = useParams()

  const uploadMethods = UpdateHock<any>({
    path: '/ready_product',
    editId: +id
  })
  const { closeUploading, openUploading } = uploadMethods
  const [open, setOpen] = useState<string>('')

  const selectLable = (label: string) => {
    setOpen(open === label ? '' : label)
  }

  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <MainPrice />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={open == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm isOpen={open == 'orderForm'} toggleItem={() => selectLable('orderForm')} />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar isEdit />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Default
