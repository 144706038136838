
export default {
  content: {
    title: 'Holiday Offers',
    slug: 'holiday-offers',
    image:  {
      url:  '/assets/placeholder/placeholder-item.svg',
      mobile: null,
      width: 450,
      height: 140
    }
  }
}
