import UpdateHock from 'helpers/UpdateHock'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Accordion, AccordionItem, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import { useFormContext } from 'react-hook-form'
import ProductSidebar from '../Components/ProductSidebar'
import CourseDetails from './components/CourseDetails'
import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import MainPrice from './components/MainPrice'
import ProductsLayout from './components/ProductsLayout'
import Specification from './components/Specification'
import { useState } from 'react';

type readyProduct = {
  title: string
  slug: string
  short_description: string
  description: string

  brand_id: number | null
  published: number
  cost: number | null
  price: number | null
  badge_id: number | null
  categories: number[]
  fees: number[]
  main_image: string
  images: string[]
  labels: number[]
  form_id: number | null
  discount: number | null
  discount_type: string
  similar_products: number[]
  meta: {
    title: ''
    keywords: ''
    description: ''
  }
}
const Course = () => {
  document.title = 'Update course product | Sallatk'

  const { id } = useParams()

  const uploadMethods = UpdateHock<readyProduct>({
    path: '/course_product',
    initValue: {
      title: '',
      slug: '',
      short_description: '',
      description: '',
      brand_id: null,
      published: 1,
      cost: null,
      price: null,
      badge_id: null,
      categories: [],
      fees: [],
      main_image: '',
      images: [],
      labels: [],
      form_id: null,
      discount: null,
      discount_type: '',
      similar_products: [],
      meta: {
        title: '',
        keywords: '',
        description: ''
      }
    },
    editId: +id
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opend === label ? '' : label)
  }

  const { closeUploading, openUploading } = uploadMethods

  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />
          <CourseDetails openUploading={openUploading} closeUploading={closeUploading} />
          <MainPrice />
          <Chapters />
          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar
            isEdit
            components={{
              Shipping: false,
              Stock: false
            }}
          />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Course

function Chapters() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { getValues } = useFormContext()
  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Product Chapters')}</h5>
      </CardHeader>
      <CardBody>
        <Button
          type="button"
          onClick={() =>
            navigate(`/products/chapters/list/${id}/${getValues().product_course_id}`)
          }>
          {t('Chapters')}
        </Button>
      </CardBody>
    </Card>
  )
}
