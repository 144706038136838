import { useTranslation } from 'react-i18next'

import { useNavigate, useParams } from 'react-router-dom'

import { Accordion, AccordionItem, Col, Container, Form, Row } from 'reactstrap'

import { Submit } from 'Components/Form'
import Loader from 'Components/IndexTable/partials/Loader'
import UpdateHock from 'helpers/UpdateHock'

import { withErrorBoundary } from 'react-error-boundary'
import { FormProvider } from 'react-hook-form'
import ProductSidebar from '../Components/ProductSidebar'
import Gallery from './components/Gallery'
import Instruments from './components/Instruments'
import MainDetails from './components/MainDetails'
import OrderForm from './components/OrderForm'
import { useState } from 'react'

const ProductWithAddedFeatures = () => {
  document.title = 'Create Product | Salltak'
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    brand_id: number | null
    hidden: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    form_id: number | null
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOption: {
      price: string
    }[]
  }

  // prepare hock
  const {
    register,
    handleSubmit,
    errors,
    update,
    loading,
    closeUploading,
    openUploading,
    setValue,
    getValues,
    getLoading,
    control,
    formMethods
  } = UpdateHock<readyProduct>({
    path: '/donation_product',
    initValue: {
      title: '',
      slug: '',
      short_description: '',
      description: '',
      brand_id: null,
      hidden: null,
      badge_id: null,
      categories: [],
      fees: [],
      main_image: '',
      images: [],
      form_id: null,
      similar_products: [],
      meta: {
        title: '',
        keywords: '',
        description: ''
      },
      featureOption: []
    },
    editId: numericId
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opend === label ? '' : label)
  }

  const submit = async (data: readyProduct) => {
    const response = await update(data)
    if (response.status) navigate('/products/list')
  }

  return (
    <div className="page-content">
      <Container fluid>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(submit)}>
            {getLoading && <Loader />}
            {!getLoading && (
              <Row>
                <Col lg={8}>
                  <MainDetails
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                  />

                  <Instruments
                    register={register}
                    errors={errors}
                    control={control}
                    getValues={getValues}
                  />

                  <Gallery
                    register={register}
                    errors={errors}
                    openUploading={openUploading}
                    closeUploading={closeUploading}
                    getValues={getValues}
                  />

                  <Accordion
                    toggle={() => {}}
                    className="lefticon-accordion custom-accordionwithicon accordion-border-box"
                    open="test">
                    <AccordionItem>
                      <OrderForm
                        isOpen={opend == 'orderForm'}
                        toggleItem={() => selectLable('orderForm')}
                        register={register}
                        errors={errors}
                        getValues={getValues}
                      />
                    </AccordionItem>
                  </Accordion>
                  <div className="text-end mb-3 m-2">
                    <Submit loading={loading} label={t('Save')} />
                  </div>
                </Col>

                <Col lg={4}>
                  {/* <Details register={register} errors={errors} getValues={getValues} />
                <Similar register={register} errors={errors} getValues={getValues} />
                <Seo register={register} errors={errors} /> */}
                  <ProductSidebar />
                </Col>
              </Row>
            )}
          </Form>
        </FormProvider>
      </Container>
    </div>
  )
}

export default withErrorBoundary(ProductWithAddedFeatures, {
  fallback: <div className="page-content">Something went wrong...</div>
})
