import { defaultImage } from "ThemeManager/Templates/DefaultValues/DefaultValues";

export default {
  content: {
    title: {
      ar: 'التطبيق متجرك ',
      en: 'Store App'
    },
    subTitle: {
      ar: '<span> شارك مشترياتك <strong>الأفكار</strong> &amp; بلا حدود <strong>بمزيد من الإعجاب</strong> </span>',
      en: '<span> Share Your <strong>Ideas</strong> &amp; Shop Endless <strong>Inspiration</strong> </span>'
    },
    appImage: defaultImage.url,
    appButtons: [
      {
        slug: '',
        appButton: defaultImage.url
      },
      {
        slug: '',
        appButton: defaultImage.url
      }
    ]
  }
}
