// react components
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
// botstrp components
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
// form inputs
import FormInputs from './FormInputs'

const SectionForm = ({ data, close, show, onSuccess, config, value }: any) => {
  const { t } = useTranslation()
  // init form hock
  const methods = useForm<any>({ defaultValues: value })
  const { handleSubmit } = methods

  const submit = async (data: any) => {
    onSuccess(data)
    close()
  }

  return (
    <Modal
      size="lg"
      isOpen={show}
      backdrop={'static'}
      id="staticBackdrop"
      centered
      className="zoomIn">
      <ModalHeader toggle={close}>{t(config.title)}</ModalHeader>
      <ModalBody className="p-3">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            <FormInputs inputs={config.form} data={data} />
            <div className="hstack gap-2 justify-content-end">
              <Button className="btn btn-link btn-secondary fw-medium" outline onClick={close}>
                <i className="ri-close-line me-1 align-middle"></i> {t('Close')}
              </Button>
              <button type="submit" className={'btn btn-secondary'}>
                {t('Update')}
              </button>
            </div>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  )
}
export default SectionForm
