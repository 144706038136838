import { title } from 'process';
import { useTranslation } from 'react-i18next'


type StatusBadgeProps = {
  status: string;
  title?: string;
};

const StatusBadge = ({ status, title }: StatusBadgeProps) => {
  const { t } = useTranslation();
  const text_title = title || t(status);

  switch (status) {
    case "paid":
      return <span className="badge text-uppercase bg-primary">{text_title}</span>;
    case "pending":
      return <span className="badge text-uppercase bg-warning">{text_title}</span>;
    case "wait":
      return <span className="badge text-uppercase bg-warning">{text_title}</span>;
    case "refused":
      return <span className="badge text-uppercase bg-danger">{text_title}</span>;
    case "canceled":
      return (
        <span className="badge text-uppercase bg-danger-subtle text-danger">{text_title}</span>
      );
    default:
      return <span className="badge text-uppercase bg-secondary">{t("Unknown Status")}</span>;
  }
};

export default StatusBadge;