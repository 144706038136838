import { useParams } from 'react-router-dom'

import { Accordion, AccordionItem, Col, Row } from 'reactstrap'

import UpdateHock from 'helpers/UpdateHock'

import Gallery from './components/Gallery'
import MainDetails from './components/MainDetails'
import OrderForm from './components/OrderForm'
// import Instruments from './components/Instruments'
import { DonationPaidFeatures } from '../Components/PaidFeatures'
import ProductSidebar from '../Components/ProductSidebar'
import ProductsLayout from './components/ProductsLayout'
import Specification from './components/Specification'
import { useState } from 'react'

const Default = () => {
  document.title = 'Create Product | Salltak'

  const { id } = useParams()

  // Convert 'id' to a number if needed
  const numericId: number = id as unknown as number

  type readyProduct = {
    title: string
    slug: string
    short_description: string
    description: string
    brand_id: number | null
    hidden: number | null
    badge_id: number | null
    categories: number[]
    fees: number[]
    main_image: string
    images: string[]
    form_id: number | null
    similar_products: number[]
    meta: {
      title: ''
      keywords: ''
      description: ''
    }
    featureOptionsPaid: {
      price?: string
      images: { image: string; web_image: string }[] | string[]
    }[]
  } | null

  // prepare hock
  const uploadMethods = UpdateHock<readyProduct>({
    path: '/donation_product',
    editId: numericId
  })

  const [opend, setOpen] = useState<string>('')
  const selectLable = (label: string) => {
    setOpen(opend === label ? '' : label)
  }

  const { closeUploading, openUploading } = uploadMethods

  return (
    <ProductsLayout uploadMethods={uploadMethods}>
      <Row>
        <Col lg={8}>
          <MainDetails />

          {/* <Instruments /> */}
          <DonationPaidFeatures isEdit={true} />

          <Gallery openUploading={openUploading} closeUploading={closeUploading} />

          <Accordion
            toggle={() => {}}
            className="lefticon-accordion custom-accordionwithicon accordion-border-box"
            open="test">
            <AccordionItem>
              <Specification
                isOpen={opend == 'spicifcation'}
                toggleItem={() => selectLable('spicifcation')}
              />
            </AccordionItem>
            <AccordionItem>
              <OrderForm
                isOpen={opend == 'orderForm'}
                toggleItem={() => selectLable('orderForm')}
              />
            </AccordionItem>
          </Accordion>
        </Col>

        <Col lg={4}>
          <ProductSidebar
            isEdit
            components={{
              Shipping: false,
              Stock: false,
              Info: false
            }}
          />
        </Col>
      </Row>
    </ProductsLayout>
  )
}

export default Default
