import DownloadApps from './DownloadApps/Config'
import Support from './Support/Config'
import Subscription from './Subscription/Config'
import Brands from './Brands/Config'
import Banners from './Banners/Config'
import Categories from './Categories/Config'
import Products from './Products/Config'
import Hero from './Hero/Config'
import Reviews from './Reviews/Config'
import Features from './Features/Config'
import Partners from './Partners/Config'

import Collection from './Collection/Config'

const config = [
  {
    title: 'Slider',
    key: 'Hero',
    sections: Hero
  },
  {
    title: 'Banners',
    key: 'Banners',
    sections: Banners
  },
  {
    title: 'Products',
    key: 'Products',
    sections: Products
  },
  {
    title: 'Categories',
    key: 'Categories',
    sections: Categories
  },
  {
    title: 'Brands',
    key: 'Brands',
    sections: Brands
  },
  {
    title: 'Partners',
    key: 'Partners',
    sections: Partners
  },
  {
    title: 'Reviews',
    key: 'Reviews',
    sections: Reviews
  },
  {
    title: 'Features',
    key: 'Features',
    sections: Features
  },
  {
    title: 'Image Collection',
    key: 'Collection',
    sections: Collection
  },
  {
    title: 'Download Apps',
    key: 'DownloadApps',
    sections: DownloadApps
  },
  {
    title: 'Support',
    key: 'Support',
    sections: Support
  },
  {
    title: 'Subscription',
    key: 'Subscription',
    sections: Subscription
  }
]

export default config
