import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import CountUp from 'react-countup'

import ReactApexChart from 'react-apexcharts'
import FetchHock from 'helpers/FetchHock'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import { useTranslation } from 'react-i18next'

interface DataPoint {
  x: string
  y: number
  id: number
}

interface ReportData {
  data: DataPoint[]
  name: string
  type: string
  total: number
}
type RevenueProps = {
  month?: number; // Optional parameter for the filter
};

const Revenue = ({ month = 0}: RevenueProps) => {
  const { t } = useTranslation()
  const [selected_month, setSelectedMonth] = useState(month||-1);
  const fetchHook = FetchHock<any, any>({
    path: '/dashboard/store-earnings-reports',
  })

  useEffect(() => {
    if (selected_month) {
      fetchHook.filter('month', selected_month)
    }
  }, [selected_month])

  const data: ReportData[] = fetchHook.result?.charts || []
  const conversationalRating = fetchHook.result?.conversional_rating || null

  const handleFetch = (month) => {
    setSelectedMonth(month); 

  };
  const periods = [
    { label: 'ALL', value: -1 },
    { label: '1M', value: 1 },
    { label: '3M', value: 3 },
    { label: '6M', value: 6 },
  ];
  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Store Earnings Reports')}</h4>
          <div className="d-flex gap-1">
          {periods.map((period) => (
              <button
                key={period.value}
                type="button"
                className={`btn ${selected_month === period.value ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
                onClick={() => handleFetch(period.value)}
              >
                {period.label}
              </button>
            ))}
          </div>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-soft-light">
          <Row className="g-0 text-center">
            {data?.map((item, index) => (
              <Col key={index}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp start={0} end={item?.total} duration={3} separator="," />
                  </h5>
                  <p className="text-muted mb-0">{t(item?.name)}</p>
                </div>
              </Col>
            ))}
            {conversationalRating ? (
              <Col>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1 text-success">
                    <CountUp
                      start={0}
                      end={conversationalRating}
                      duration={3}
                      separator=","
                      suffix="%"
                    />
                  </h5>
                  <p className="text-muted mb-0">{t('Conversational Rating')}</p>
                </div>
              </Col>
            ) : null}
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              <ViewDataWithLoading
                style={{ height: '370px' }}
                items={data}
                loading={fetchHook.loading}
                error={fetchHook.error}>
                <RevenueCharts series={data?.filter((item) => item?.data)} />
              </ViewDataWithLoading>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Revenue

const RevenueCharts = ({ series }) => {
  var options = {
    series: series,
    chart: {
      height: 370,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 8],
      width: [2, 0, 2.2]
    },
    fill: {
      opacity: [0.1, 0.9, 1]
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4
      }
    },
    xaxis: {
      type: 'category',
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10
      }
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        barHeight: '70%'
      }
    }
  }
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  )
}
