import { CardImg } from 'reactstrap'
import './AvatarStack.css' // Create this CSS file for custom styles

export const AvatarStack = ({ values }) => {
  return (
    <div className="avatar-stack">
      {values?.map((avatar, index) => (
        <CardImg
          key={index}
          src={avatar.preview}
          onError={(e) => (e.currentTarget.src =  '/assets/placeholder/placeholder-item.svg')}
          className="avatar"
        />
      ))}
    </div>
  )
}
