import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// required components
import Sidebar from './Sidebar'
import Previewer from './Previewer'
import linkGenerator from 'helpers/linkGenerator'
import axios from 'axios'

// successs response
import successNotfication from 'helpers/SuccessNotfication'
import { Spinner } from 'reactstrap'
import CreateModal from './components/CreateModal'

const ThemeBuilderContext = createContext<any>(null)

export const useThemeBuilder = () => {
  const context = useContext(ThemeBuilderContext)
  return context
}

const ThemeBuilder = () => {
  const { t } = useTranslation()

  const [theme, setTheme] = useState<any>({})
  const [sections, setSections] = useState<any>([])

  // get theme from backend
  const getTheme = () => {
    axios.get(linkGenerator(`/settings/theme`)).then((response) => {
      setSections(response.data.result.index)
      setTheme(response.data.data)
    })
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [loading_draft, setLoadingDraft] = useState<boolean>(false)
  const saveTheme = async () => {
    setLoading(true)
    await axios.post(linkGenerator(`/settings/theme`), { json: JSON.stringify(theme) })
    successNotfication(t('Theme updated successfully'))
    setLoading(false)
  }
  const saveDraftTheme = async () => {
    setLoadingDraft(true)
    await axios.post(linkGenerator(`/settings/theme-draft`), { json: JSON.stringify(theme) })
    successNotfication(t('Theme draft updated successfully'))
    setLoadingDraft(false)
  }

  const addSection = (sectionData: any, position: number) => {
    const oldSections = [...sections]
    oldSections.splice(position, 0, sectionData)
    setSections(oldSections)  
  }

  const updateSection = (id: number, sectionData: any) => {
    const oldSections = [...sections]
    oldSections[id].data.data = sectionData
    setSections(oldSections)
  }

  const deleteSection = (index: number) => {
    const oldSections = [...sections]
    oldSections.splice(index, 1)
    setSections(oldSections)
  }

  // drag handeler
  const [dragedItem, setDragedItem] = useState(null)
  const [showDragPositions, setShowDragPositions] = useState(true)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = () => {changeShowCreate(true)}
  const closeCreate = () => changeShowCreate(false)


  const handelDrag = (e: React.DragEvent, item: any) => {
    e.dataTransfer.setData('data', item)
    setDragedItem(item)
    setShowDragPositions(true)
  }
  const handelDrop = (e: React.DragEvent, index: number) => {
    addSection(dragedItem, index)
    setShowDragPositions(false)
  }
  const handelDragOver = (e: React.DragEvent) => e.preventDefault()

  useEffect(() => getTheme(), [])

  useEffect(() => {
    const oldTheme = { ...theme };
    oldTheme.index = sections; // Sync `theme.index` with `sections`
    setTheme(oldTheme);
  //  console.log('Sections updated:', sections);
  }, [sections]);
  
  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          disabled={loading}
          type="button"
          className={'btn btn-secondary mb-3'}
          onClick={() => saveTheme()}>
          {loading ? <Spinner color="light" size="sm" /> : t('Publish')}
        </button>
        &nbsp;
        <button
          disabled={loading_draft}
          type="button"
          className={'btn btn-secondary mb-3'}
          onClick={() => saveDraftTheme()}>
          {loading_draft ? <Spinner color="light" size="sm" /> : t('Save Draft')}
        </button>
        &nbsp;
        <button
        
          type="button"
          className={'btn btn-secondary mb-3'}
          onClick={() => openCreate()}>
          {t('Preview')}
        </button>
      </div>

      <ThemeBuilderContext.Provider
        value={{
          handelDrag,
          handelDragOver,
          showDragPositions,
          saveTheme,
          loading,
          sections,
          updateSection,
          deleteSection,
          handelDrop,
           addSection
        }}>
        {/* <Sidebar /> */}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Previewer />
        </div>
      </ThemeBuilderContext.Provider>
      {showCreate && <CreateModal close={closeCreate} show={openCreate} onSuccess={fetch} />}

    </div>
  )
}

export default ThemeBuilder
