import { useTranslation } from 'react-i18next'

import { Row, Col, Card, CardBody, CardImg, CardTitle, CardText, CardHeader } from 'reactstrap'
import { LazySelect } from 'Components/Form'
import { useFormContext } from 'react-hook-form'
import { useState } from 'react'

const Package = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState([])
  const {
    register,
    formState: { errors },
    watch
  } = useFormContext()
  return (
    <Card>
      <CardHeader>
        <h5 className="card-title mb-0">{t('Package products')}</h5>
      </CardHeader>
      <CardBody>
        <LazySelect
          isMulti
          label={t('Package products')}
          placeholder={t('Enter package products')}
          model={register('packageProductIds')}
          propTitle="title"
          error={errors.packageProductIds}
          path="/products"
          onChange={(value) => setSelected(value)}
        />
        <ProductsPreview
          selectedProductIds={watch('packageProductIds')}
          selectedProductData={selected}
        />
      </CardBody>
    </Card>
  )
}

export default Package

const removeDuplicates = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}

export function ProductsPreview({ selectedProductIds, selectedProductData }) {
  const { t } = useTranslation()

  if (selectedProductIds === undefined || selectedProductIds.length === 0) return null

  // const uniqueProducts: any = removeDuplicates(selectedProductData, 'id')

  // const matchedProducts = uniqueProducts.filter((product) =>
  //   selectedProductIds.some((selectedId) => selectedId === product.id)
  // )
  const matchedProducts = selectedProductIds.map((id) =>
    selectedProductData.find((product) => product.id === id)
  )
  return (
    <Row>
      {matchedProducts?.map((product) => (
        <Col sm="6" md="4" key={product.id} className="mb-3">
          <Card>
            <CardImg
              top
              width="100%"
              style={{ aspectRatio: '1/1', objectFit: 'cover' }}
              src={product?.web_main_image || '/sallatk-happy.png'}
              alt={product.title}
              onError={(e) => (e.currentTarget.src =  '/assets/placeholder/placeholder-item.svg')}
            />
            <CardBody>
              <CardTitle tag="h5">{product.title}</CardTitle>
              <CardText className="m-0">{product.short_description}</CardText>
              <CardText className="m-0">
                <small className="text-muted">
                  {t('Price')}: {product.final_price} {product.currency}
                </small>
              </CardText>
              <CardText>
                <small className="text-muted">
                  {t('Stock')}: {product.stock}
                </small>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}
