import { Button, Col, Container, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Submit, Radio } from 'Components/Form'
import PopUp from 'Components/PopUp'
import UpdateHock from 'helpers/UpdateHock'
import FetchHock from 'helpers/FetchHock'
import DynamicSettingsForm from 'pages/settings/edit/DynamicSettingsForm'

function FontsAndColors({ show, close }: any) {
  const { t } = useTranslation()
  const fetchHook = FetchHock<any>({
    path: `/settings`,
    initFilter: { group_name:  'theme' }
  })


  const { register, handleSubmit, errors, update, loading, watch } = UpdateHock<any>({
    path: '/settings',
    initValue: {
      logoStyle: ''
    },
    allowGet: false
  })

  const submit = async (data: any) => {
    const response = await update({
      data: [{ key: 'logo_style', value: data.logoStyle }]
    })
    if (response.status) {
      close()
    }
  }
  return (
    <PopUp title={t('Manage Fonts and Colors')} show={show} close={close} size='xl' >
      <form onSubmit={handleSubmit(submit)}>
        <Container fluid>
          <Row>
          <DynamicSettingsForm key={0} fields={fetchHook.items[0]?.items || []} />
          </Row>
        </Container>

       
      </form>
    </PopUp>
  )
}

export default FontsAndColors
