import classnames from 'classnames'
import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'

import BreadCrumb from 'Components/Common/BreadCrumb'
import Loader from 'Components/Common/Loader'
import CategoryButtons from 'Components/Common/SearchCategories'
import { useAppSearch } from 'Components/Common/SearchOption'
import clsx from 'clsx'

const SearchResults = () => {
  document.title = 'Search Results | Sallatk'

  let [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState('1')

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <BreadCrumb title="Search Results" pageTitle="Home" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <Row className="justify-content-center mb-4">
                    <Col lg={6}>
                      <Row className="g-2">
                        <Col>
                          <div className="position-relative mb-3">
                            <Input
                              type="text"
                              className="form-control bg-light border-light"
                              placeholder="Search here.."
                              value={searchParams.get('term')}
                              onChange={(e) => {
                                const term = e.target.value
                                const newSearchParams = new URLSearchParams(searchParams)
                                newSearchParams.set('term', term)
                                setSearchParams(newSearchParams)
                              }}
                            />
                          </div>
                        </Col>
                        <div className="col-auto">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light">
                            <i className="mdi mdi-magnify me-1"></i> Search
                          </button>
                        </div>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <h5 className="fs-16 fw-semibold text-center mb-0">
                        Showing results for "
                        <span className="text-primary fw-medium fst-italic">
                          {searchParams.get('term')}
                        </span>{' '}
                        "
                      </h5>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="justify-content-center">
                  <Col lg={6} className="text-center">
                    <CategoryButtons
                      onChangeType={(option) => {
                        const newSearchParams = new URLSearchParams(searchParams)
                        newSearchParams.set('type', option.value)
                        setSearchParams(newSearchParams)
                      }}
                      type={searchParams.get('type')}
                    />
                  </Col>
                </Row>
                <div>
                  <Nav className="nav-tabs nav-tabs-custom" color='secondary' role="tablist">
                    <NavItem>
                      <NavLink

                        href="javascript:void(0)"
                        color='secondary'
                            className={clsx(
                  'active bg-secondary',
                  'cursor-pointer text-dark'
                )}
                        onClick={() => {
                          toggleTab('1')
                        }}>
                        <i className="ri-search-2-line text-muted align-bottom me-1"></i> All
                        Results
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <ResultList searchParams={searchParams} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SearchResults

const ResultList = ({ searchParams }) => {
  const { isLoading, data } = useAppSearch({
    term: searchParams.get('term'),
    type: searchParams.get('type')
  })

  if (isLoading) {
    return <Loader />
  }

  const results = data?.data?.result?.data || []
  if (!results?.length) {
    return <h6 className="text-center text-muted">No results found</h6>
  }

  return (
    <div>
      {results?.map((result) => {
        const type = result?.type?.toLowerCase()
        const id = result?.id

        return (
          <div key={result.id} className="pb-3">
            <h5 className="mb-1">
              <Link to={getSearchResultLink(type, id)}>{result.title}</Link>
            </h5>
            <p className="text-success mb-2">{result?.type}</p>
            <p className="text-muted mb-2">{result?.short_description}</p>
          </div>
        )
      })}
    </div>
  )
}

export const getSearchResultLink = (type, id) => {
  return `/${type}/list?id=${id}`
}
