import WithCategory from './withCategory/Config'
import WithCategoryDynamicSlider from './withCategoryDynamicSlider/Config'

const config = [
  {
    title: 'Dynamic',
    key: 'withCategory',
    config: WithCategoryDynamicSlider
  },
  {
    title: 'Cusom',
    key: 'withCategoryCustom',
    config: WithCategory
  }
]

export default config
