import BreadCrumb from 'Components/Common/BreadCrumb'
import { Switch } from 'Components/Form'
import ViewDataWithLoading from 'Components/ViewDataWithLoading'
import FetchHock from 'helpers/FetchHock'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'

import ImageWithFallback from 'helpers/ImageComponent'
import UpdateHock from 'helpers/UpdateHock'
import CreateModal from '../Components/CreateModal'
import { secureHeapUsed } from 'crypto'

export type SettingsItem = {
  key: string
  required: 0 | 1
  type: 'number' | 'text'
  value: string | null
}

export type SettingsGroup = {
  id: number
  group: string
  logo: string
  enabled: 0 | 1
  items: SettingsItem[]
}

const ApiIntegrationsList = () => {
  document.title = 'Integration Settings | Sallatk'

  const { t } = useTranslation()

  const { items, loading, error, invalidate, fetch } = FetchHock<SettingsGroup>({
    path: '/settings-cstm'
  })

  const [updateItem, changeUpdateItem] = useState(null)
  const [showCreate, changeShowCreate] = useState<boolean>(false)
  const openCreate = (it) => {
    changeUpdateItem(it)
    changeShowCreate(true)
  }
  const closeCreate = () => {
    changeShowCreate(false)
    changeUpdateItem(null)
  }
  // Define categories and groups as JSON
  const categories = {
    authentication: ['facebook', 'instagram'],
    communication: ['livechat'],
    security: ['captcha']
  }
  // Collect all predefined groups
  const allDefinedGroups = Object.values(categories).flat()
  // Filter uncategorized items
  const uncategorizedItems = items.filter(
    (item) => !allDefinedGroups.includes(item.group.toLowerCase())
  )

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb />
        <ViewDataWithLoading items={items} loading={loading} error={error}>
          {Object.entries(categories).map(([category, groups]) => {
            // Filter items that match the current group
            const filteredItems = items.filter((item) =>
              groups.includes(item.group.toLowerCase())
            )

            if (filteredItems.length > 0) {
              return (
                <div key={category}>
                  {/* Section Header */}
                  <h4 className="mt-4">{t(category.charAt(0).toUpperCase() + category.slice(1))}</h4>
                  <Row className="mt-4">
                    {filteredItems.map((item, key) => (
                      <Col key={key} xl={2} lg={4} md={6} sm={6} className="col">
                        <Card className="ribbon-box right overflow-hidden">
                          <CardBody className="text-center p-4 d-flex flex-column align-items-center">
                            <ImageWithFallback src={item.logo} alt={item.group} height={55} />
                            <h5 className="my-3">{item.group}</h5>
                            <SettingsActions item={item} openCreate={openCreate} invalidate={invalidate} ></SettingsActions>

                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              )
            }
            return null
          })}

          {/* Others Section */}
          {uncategorizedItems.length > 0 && (
            <div>
              <h4 className="mt-4">{t('Others')}</h4>
              <Row className="mt-4">
                {uncategorizedItems.map((item, key) => (
                  <Col key={key} xl={2} lg={4} md={6} sm={6} className="col">
                    <Card className="ribbon-box right overflow-hidden">
                      <CardBody className="text-center p-4 d-flex flex-column align-items-center">
                        <ImageWithFallback src={item.logo} alt={item.group} height={55} />
                        <h5 className="my-3">{item.group}</h5>
                        <SettingsActions item={item} openCreate={openCreate} invalidate={invalidate} ></SettingsActions>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </ViewDataWithLoading>
      </Container>
      {showCreate && (
        <CreateModal data={updateItem} close={closeCreate} show={openCreate} onSuccess={fetch} />
      )}
    </div>
  )
}

export default ApiIntegrationsList

function ChangeStatus({ item, onSuccess }) {
  const { t } = useTranslation()
  const { update, loading } = UpdateHock({
    path: `/settings-cstm/${item.id}/status`,
    allowGet: false
  })
  const updateStatus = async () => {
    const response = await update({})
    if (response.status) {
      await onSuccess()
    }
  }
  return (
    <Switch loading={loading} label={''} checked={item.enabled} onChange={updateStatus} />
  )
}
const SettingsActions = ({ item, openCreate, invalidate }) => (
  <div className="d-flex align-items-center justify-content-between ">
  <ChangeStatus item={item} onSuccess={invalidate} />
  <Button
  className='btn btn-secondary btn-icon'
    size="sm"
    color="primary"
    onClick={() => {
      openCreate(item)
    }}>
    <i className="mdi mdi-cog-outline "></i>
  </Button>
</div>
)